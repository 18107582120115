<template>
  <div class="ma-2">
   <v-layout class="px-3">
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="$router.push(`/library/books`)" tile class="mx-2"> Manage Books </v-btn>
      <v-btn outlined color="primary" @click="$router.push('/library/settings')" tile> Settings </v-btn>
   </v-layout>
   <v-row>
      <v-col cols="3" class="pl-12 my-4" v-for="(widget, index) in widgets" :key="index">
        <v-card class="d-flex align-center rounded-lg" elevation="3" height="100">
          <v-list-item two-line class="pr-0">
            <v-list-item-avatar size="55" :color="widget.color" class="py-auto my-auto ml-n10 rounded-lg">
              <v-icon dark>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content :style="`border-right: 0.625vw solid ${widget.color}; border-radius: 8px`">
              <v-list-item-title class="text-h6 font-weight-bold"> {{ widget.text }} </v-list-item-title>
              <v-list-item-title class="text-right text-h5 mb-1 font-weight-bold pr-3">{{ widget.value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInit"></data-iterator>
    <v-dialog v-model="drawer" absolute bottom temporary right width="500px">
      <v-card elevation="0" height="100%">
        <v-card-title>
          {{ (modelObj._id) ? 'Update Issued book status' : 'Issue Book' }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form">
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="12">
                <v-autocomplete outlined dense hide-details label="User" v-model="modelObj.user_id" :items="listOfUsers" item-value="_id" :rules="setValidation('requiredValidation')"
                  :item-text="(val) => `${val.formdata.name} ${(val.role && val.role.name) ? '(' + val.role.name + ')' : ''}`"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete outlined dense hide-details label="Book" v-model="modelObj.book_id" :items="listOfBooks" item-value="_id" item-text="name" :rules="setValidation('requiredValidation')"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-menu v-model="modelObj.date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="modelObj.date" label="Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                  </template>
                  <v-date-picker v-model="modelObj.date" @input="modelObj.date_menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu v-model="modelObj.return_date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="modelObj.return_date" label="Return Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                  </template>
                  <v-date-picker v-model="modelObj.return_date" @input="modelObj.return_date_menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Fine Amount" v-model="modelObj.fine"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-if="modelObj._id" outlined dense hide-details label="Status" v-model="modelObj.status" :rules="setValidation('requiredValidation')" :items="issuedBooksStatusList" item-text="text" item-value="value"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      reInit: 0,
      drawer: false,
      showLibrarySettings: false,
      search: '',
      widgets: [{
        text: 'Total Books',
        value: '45',
        total: '689',
        color: 'blue'
      }, {
        text: 'Student with Boooks',
        value: '45',
        total: '689',
        color: 'green'
      }, {
        text: 'OverDue Boooks',
        value: '45',
        total: '689',
        color: 'pink'
      }, {
        text: 'Underactive Boooks',
        value: '45',
        total: '689',
        color: 'orange'
      }],
      listOfRecords: [],
      listOfBooks: [],
      listOfUsers: [],
      payload: {
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      },
      options: {},
      modelObj: {},
      librarySettingObj: {}
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  watch: {
    drawer(val) {
      if (!val) {
        this.modelObj = {}
      }
    }
  },
  mounted () {
    this.getListHandler()
    this.getListOfBooks()
    this.getAllUsers()
  },
  computed: {
    issuedBooksStatusList () {
      return [{
        text: 'Not Returned',
        value: 1
      }, {
        text: 'Returned',
        value: 2
      }]
    },
    payloadHeaders () {
      return [{
        text: 'User Name',
        align: 'start',
        sortable: false,
        value: 'user_id.formdata.name',
      }, {
        text: 'Book Name',
        value: 'book_id.name'
      }, {
        text: 'Author',
        value: 'book_id.author'
      }, {
        text: 'Issued Date',
        value: 'date',
        type: 'DATE'
      }, {
        text: 'Return Date',
        value: 'return_date',
        type: 'DATE'
      }, {
        text: 'Status',
        value: 'status',
        type: 'SELECT',
        items: this.issuedBooksStatusList,
        condition: ({ header, item }) => this.issuedBooksStatusList.find((x) => x.value === item[header.value]).text
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'issued_books').then(response => {
        this.payload.items = response.data
        this.reInit++
      })
    },
    getListOfBooks () {
      this.$_execute('get', 'books').then(response => {
        this.listOfBooks = response.data
      })
    },
    getAllUsers () {
      this.$store.dispatch('getUsersList', {}).then((data) => {
        this.listOfUsers = data
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `issued_books/${this.modelObj._id}` : `/issued_books`
      let method = this.modelObj._id ? `put` : `post`
      if (!this.modelObj._id) {
        this.modelObj.status = 1
      }
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `issued_books/${id}`).then(response => {
        this.drawer = true
        let model = response.data[0]
        model.date = model.date ? moment(model.date, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD') : ''
        model.return_date = model.return_date ? moment(model.return_date, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD') : ''
        this.modelObj = model
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'issued_books',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
